$(document).ready(function () {
    //main Navigation
    function classToggle() {
        const navs = document.querySelectorAll('.main_nav-items')

        navs.forEach(nav => nav.classList.toggle('main_nav-toggleShow'));
    }
    document.querySelector('.main_nav-link-toggle')
        .addEventListener('click', classToggle);



    //Modal on page load
    // $(window).on('load', function () {
    //     $('#signUpModal').modal('show');
    // });
   


}); // end document.ready function is a go